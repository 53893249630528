import * as React from "react";

import { Layout } from "components";
import {
  EwalletsExploreSection,
  EwalletsFeatures,
  EWalletsPageHeader,
} from "templates/solutions/ewallets";

const EWalletsPage = () => (
  <Layout title="E-Wallets/Virtual Accounts">
    <EWalletsPageHeader />
    <EwalletsFeatures />
    <EwalletsExploreSection />
  </Layout>
);

export default EWalletsPage;
