import React from "react";
import { ExploreCard } from "components";
import { StaticImage } from "gatsby-plugin-image";

const EwalletsExploreSection = () => {
  return (
    <ExploreCard
      subTitle="Want to do more? "
      title="e-Wallets"
      description="Send and receive money to any bank account in Nigeria with a
    virtual account number."
      buttonText="Explore e-Wallet services"
      image={
        <StaticImage
          src="../../../../assets/images/jpegs/ewallets/finance-infrastructure-parkway.jpeg"
          alt="Lady smiling in front of a laptop"
        />
      }
    />
  );
};

export { EwalletsExploreSection };
