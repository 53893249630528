import React from "react";
import { PageFeatures } from "components";
import { StaticImage } from "gatsby-plugin-image";

const EwalletsFeatures = () => {
  return (
    <div>
      <PageFeatures
        features={[
          "Verify & identify users via our KYC  and onboarding solution   ",
          "Move and receive funds to listed bank accounts",
          "Recurring payments automation",
        ]}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/ewallets/features.jpg"
            alt="features"
          />
        }
      />
    </div>
  );
};

export { EwalletsFeatures };
