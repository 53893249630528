import { Br, PageHeader } from "components";
import React from "react";

const EWalletsPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          Easy access to consumer and <Br on="desktop" />
          business{" "}
          <span className="text-primary">
            deposit accounts/
            <Br on="desktop" />
            wallets for your customers
          </span>
        </>
      }
      subheading={
        <>
          Our wallets/virtual accounts APIs enable you to verify user
          information and <Br on="desktop" />
          transaction details as they send, receive and manage their money.
        </>
      }
      button={{ text: "Get Wallets", to: "/get-demo" }}
    />
  );
};

export { EWalletsPageHeader };
